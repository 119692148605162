@import 'src/styles/variables';
@import 'src/styles/mixins';

.row {
  @include verticalGap(14px);
  flex-direction: row;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.07);
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 16px;
  transition: box-shadow 150ms linear;

  &:hover {
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.rowContent {
  @include verticalGap(4px);
  max-width: 82%;
}

.heading {
  display: flex;
  font-size: 16px;
}

.accountName {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: $boldFontWeight;
  margin-right: 4px;
}

.accountNumber {
  letter-spacing: 1px;
}

.accountBalance {
  font-size: 14px;
}

.checkbox {
  min-width: 24px;
  min-height: 24px;
  background: linear-gradient(268.9deg, #47e497 3.88%, #47e0d6 99.06%);
  box-shadow: 0 3px 6px rgba(112, 217, 192, 0.7);
  border-radius: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  input {
    display: none;
  }

  .checkbox__inner {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $white;
    border-radius: 100%;
    box-shadow: inset 0 3px 6px rgba(112, 217, 192, 0.7);
    transition: background 150ms linear;

    svg {
      opacity: 0;
      transition: opacity 150ms linear;
      margin-right: 1px;
    }

    &.selected {
      background: linear-gradient(268.9deg, #47e497 3.88%, #47e0d6 99.06%);
      box-shadow: none;

      svg {
        opacity: 1;
      }
    }
  }
}
