@import 'src/styles/mixins';
@import 'src/styles/variables';

.dropdownContainer {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  margin: 16px 0 5px;
  -webkit-tap-highlight-color: transparent;
}

.dropdownHeader {
  position: inherit;
  display: flex;
  max-width: 251px;
  flex-direction: row;
  padding: 16px 24px;
  background-color: $white;
  border: 1px $black10 solid;
  border-radius: 12px;
  box-shadow: $deepBoxShadow;
  cursor: pointer;
  gap: 8px;
  transition: 0.2s ease-in-out;
  white-space: nowrap;
}

.dropdownHeaderActive {
  border-radius: 12px 12px 0 0;
  transition: 0.2s ease-in-out;
}

.dropdownHighlightSubtitle {
  font-weight: $boldFontWeight;
  color: $primaryColor;
  padding-right: 40px;
}

.selectArrow {
  position: absolute;
  top: 47%;
  right: 24px;
  transition: 0.2s ease-in-out;
  transform: translateY(-50%);

  path {
    stroke: $primaryColor;
  }
}

.selectArrowActive {
  transition: 0.2s ease-in-out;
  transform: translateY(-50%) rotate(180deg);
}

.dropdownContent {
  position: absolute;
  display: none;
  top: 56px;
  left: 50%;
  padding: 24px;
  width: 85%;
  max-width: 336px;
  z-index: 2;

  border-radius: 24px;
  border-right: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;

  background-color: $white;
  box-shadow: $otherBoxShadow;

  color: $black60;
  text-align: left;

  p {
    margin-bottom: 1em;
    line-height: 172%;
  }

  transform: translateX(-50%);
}

.dropdownContentActive {
  display: block;
}

.dropdownOption {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdownOptionSpace {
  p {
    margin-bottom: 0.5em;
  }
}

.dropdownOptionText {
  font-weight: $boldFontWeight;
  color: $black100;
}

.dropdownOptionHighlightText {
  @include h2;
  color: $primaryColor;
}
