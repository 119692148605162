@import 'src/styles/mixins';
@import 'src/styles/variables';

.subtitleContainer {
  @include verticalGap(16px);

  p {
    font-size: 56px;
    font-weight: $extraBoldFontWeight;
    color: $purple400;
    line-height: 67.2px;
  }

  span {
    @include body-text(md);
  }
}

.loanTermsContainer {
  @include verticalGap(12px);
}

.loanTermsTitle {
  @include body-text(lg, 700);
}

.buttonContainer {
  @include verticalGap(20px);
}

.term {
  @include body-text(lg);

  display: flex;
  justify-content: space-between;

  .termTitle {
    color: $black60;
  }

  .termValue {
    font-weight: $boldFontWeight;
  }
}

.loanBreakdownContainer {
  @include verticalGap(12px);
}
