@import 'src/styles/mixins';
@import 'src/styles/variables';

.checkIcon {
  width: 56px;
  height: 56px;
}

.questionsTitle,
.whatsNextTitle {
  font-weight: $boldFontWeight;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: $black100;

  @include sm {
    font-size: 24px;
  }
}

.contactsContainer {
  @include verticalGap(16px);
  max-width: 864px;

  @include sm {
    flex-direction: row;
  }
}

.contactItemContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 16px;
  background-color: $white;
  box-shadow: 0 16px 48px rgba(36, 52, 91, 0.08);
  border-radius: 20px;
  text-decoration: none;
  cursor: pointer;
  text-transform: none;

  @include sm {
    max-width: 416px;
    width: 45%;
    padding: 58px 16px;
  }
}

.contactsTitle {
  font-weight: $mediumFontWeight;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 1px;
  color: $black100;
  margin: 24px 0 4px;
}

.contactsSubtitle {
  font-size: 12px;
  font-weight: $regularFontWeight;
  line-height: 20px;
  color: $black60;
}

.whatsNextContainer {
  @include verticalGap(24px);

  .subtitle {
    @include body-text(lg);
    text-align: center;
  }

  button {
    max-width: 500px;
  }
}

.primaryButton {
  max-width: 500px;
  align-self: center;
}
