@import 'src/styles/variables';
@import 'src/styles/mixins';

.row {
  width: 100%;
  padding: 16px 0;
  border-bottom: 1px solid $black6;
  transition: height 1s linear;

  &:last-child {
    border-bottom: 0;
    margin-bottom: -16px;
  }
}

.overchargedList {
  &:last-child {
    border-bottom: 0;
  }
}

.rowHeader {
  cursor: pointer;
  display: flex;

  :first-child {
    flex: 1;
  }
}

.rowHeader > div {
  display: flex;
  flex-direction: column;
}

.rowText {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.rowHeaderRight {
  display: flex;
  justify-content: center;
  align-items: baseline !important;
  margin-bottom: 4px;
}

.rowTitle {
  @include body-text('md');
}

.sparkLine {
  height: 4px;
  border-radius: 8px;
  background-color: $black6;
  width: 100%;

  .sparkLineValue {
    height: 4px;
    border-radius: 8px;
    transition: width 1s cubic-bezier(0.74, 0.72, 0.82, 1);
  }
}

.rowApr {
  @include body-text('sm');
  color: $black40;
  margin-right: 8px;
}

.rowBalance {
  @include body-text('md', 500);
}

.dropdownIcon {
  margin-left: 12px;
  width: 20px;
  height: 20px;
  transition: transform 500ms ease;

  > path {
    stroke: $primaryColor;
  }

  &.expanded {
    transform: rotate(180deg);
  }
}

.dropdownContent {
  overflow: hidden;
  transition: height 400ms ease;
  margin-left: 12px;
}

// Account list
.accountRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 24px 12px 0;

  &:last-child {
    margin-bottom: 0;
  }

  .accountName {
    @include body-text('md');
  }

  .accountAmount {
    @include body-text('md');
  }
}

.accountRow > div {
  display: flex;
  width: 75%;
  margin-right: 8px;
}

.accountRow .accountName {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  align-self: flex-end;
}

.accountLogo {
  margin-right: 10px;
  width: 40px;
  min-width: 40px;
  height: 24px;
  border-radius: 50%;
  object-fit: contain;
}
