@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  display: inline-flex;
  align-items: center;
  color: $primaryColor;
  cursor: pointer;

  .icon {
    height: 14px;
    margin-left: 4px;
  }
}
