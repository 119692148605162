@import 'src/styles/variables';
@import 'src/styles/mixins';

.subtitle {
  @include subtitle;
  font-size: 16px;
  color: $black100;

  span {
    font-weight: $mediumFontWeight;
    color: $blue400;
  }
}

.comparisonContainer {
  @include verticalGap(16px);
}

.comparisonHeader {
  @include body-text('lg');
  text-align: center;

  span {
    font-weight: $boldFontWeight;
  }
}

.disclaimerContainer {
  @include disclaimerContainer;
}
