@import 'src/styles/variables';
@import 'src/styles/mixins';

.cardContainer {
  @include verticalGap(20px);

  .cardSummaryContainer {
    @include verticalGap(12px);
    width: 100%;
    align-self: center;

    div {
      @include body-text('lg');
      display: flex;
      justify-content: space-between;
      color: $black60;

      span {
        color: $black100;
        font-weight: $boldFontWeight;
      }
    }
  }
}
