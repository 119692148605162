@import 'src/styles/variables';
@import 'src/styles/mixins';

.titleHighlight {
  color: $purple400;
}

.subtext {
  color: $black80;
  text-align: center;
  margin: 0 8px;
  @include body-text('md');
}

.disclaimerContainer {
  @include notification;

  color: $black100;
  text-align: center;
}

.buttonsContainer {
  @include verticalGap(20px);
}
