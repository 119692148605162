@import 'src/styles/mixins';
@import 'src/styles/variables';

.termTitle {
  @include body-text(lg);

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 15px;
  color: $black60;
}

.tradelineContainer {
  display: flex;
  align-items: center;

  .termValue {
    @include body-text(lg, 700);
    color: $black100;
    margin-left: auto;
    padding-right: 24px;
  }
}

.tradelineLogo {
  max-width: 24px;
  margin-right: 12px;
}
