@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  @include cardContainer;
  @include verticalGap(16px);

  padding: 0;
  background-color: transparent;
  border: 0;

  @include sm {
    padding: 0;
  }
}

.join {
  background-color: $white;
  border-radius: 24px;
}

.card {
  @include cardContainer;
}

.separator {
  background-color: $black6;
  height: 1px;
  width: 100%;
}

.totalDebtLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: $boldFontWeight;
}

.container.join {
  @include verticalGap(24px);
  padding: 16px;

  @include sm {
    padding: 48px;
  }
}

.totalDebtAmount {
  font-size: 24px;
  font-weight: $semiBoldFontWeight;
}
