@import 'src/styles/variables';
@import 'src/styles/mixins';

.comparisonTable {
  @include body-text(md);

  width: 100%;
  border-spacing: 0;
}

.tooltipContainer {
  display: flex !important;
  flex-direction: column;
  max-width: 200px;
  background-color: $white !important;
  border-radius: 12px !important;
  opacity: 1 !important;
  color: $black80 !important;
  border: 1px solid $black10 !important;
  box-shadow: $deepBoxShadow;
  padding: 16px 22px !important;

  span {
    @include body-text(md, $boldFontWeight);
    color: $primaryColor;
  }
}

.tooltipContainer::after {
  border-right-color: $black10 !important;
}

.comparisonTable th,
.comparisonTable td {
  border: 1px solid $black10;
  border-right: 0;
  padding: 5px 4px;
  text-align: center;
  vertical-align: middle;
  border-bottom: 0;
}

.comparisonTable thead th {
  background-color: $black6;
  border-bottom: 0;
  min-width: 63px;
  font-weight: $mediumFontWeight;
}

.comparisonTable thead th:last-child {
  background-color: $purple50;
  border-right: 1px solid $black10;
}

.comparisonTable tr td {
  @include sm {
    width: 155px;
  }
}

.comparisonTable tr td:last-child {
  $rgba-color: rgba(red($purple50), green($purple50), blue($purple50), 0.5);

  background-color: $rgba-color;
  border-right: 1px solid $black10;
}

.thWithRadius {
  border-top-left-radius: 10px;
}

.comparisonTable th:first-child {
  background-color: transparent;
  border: 0;
}

.comparisonTable th:last-child {
  border-top-right-radius: 10px;
}

.comparisonTable td {
  color: $black80;
  line-height: 22px;

  svg {
    vertical-align: middle;
  }
}

.infoTooltipIcon {
  width: 16px;
  height: 16px;
  margin: 0 0 1px 4px;
  cursor: pointer;
}

.comparisonTable tr td:first-child {
  border-left: 0;
  padding: 12px 8px 12px 0;
  font-weight: $mediumFontWeight;
  color: $black100;
  min-width: 93px;
}

.comparisonTable tr:last-child td {
  border-bottom: 1px solid $black10;
}

.highlightText {
  font-weight: $boldFontWeight;
  color: $purple400 !important;
}

.comparisonTable th:first-child,
.comparisonTable td:first-child {
  text-align: left;
}

.purpleCheckIcon path {
  fill: $purple400;
}

.grayCrossIcon path {
  stroke: $black60;
}
