@import 'src/styles/mixins';
@import 'src/styles/variables';

.container {
  @include stepsContainer;
  text-align: center;
  margin-top: 40px;
}

.iconContainer {
  margin-top: 40px;
  margin-bottom: 24px;
}

.title {
  @include h2;
  margin-bottom: 24px;
}

.description {
  @include subtitle;
  margin-bottom: 24px;
  text-align: center;
}

.loanBreakdownContainer {
  @include verticalGap(16px);
}

.expanderContainer {
  text-align: left;
  font-size: 12px;
  border: 1px solid $black10;
  box-shadow: $deepBoxShadow;
  border-radius: 12px;
  margin-bottom: 24px;
}

.expanderOpen {
  border-bottom: 1px solid $black6;
  padding: 0 16px 16px;
}

.loanBreakdownTitle {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: $black100;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $white;
  padding: 32px 16px;
}
