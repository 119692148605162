@import 'src/styles/variables';
@import 'src/styles/mixins';

.highLightTitle {
  color: $primaryColor;
}

.containerPaycheckFlow {
  @include verticalGap(10px);

  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.paycheckFlowCard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  max-width: 100px;
  height: 90px;
  padding: 12px;
  border-radius: 12px;
  border: 1px solid $black10;
  background: $white;
  box-shadow: $deepBoxShadow;

  .arrow {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 105%;
    transform: translate(-50%, -50%);
  }

  svg {
    max-width: 80px;

    @include sm {
      max-width: 95px;
    }
  }
}

.payCheckFlowCardName {
  margin-top: 14px;
  text-align: center;

  @include body-text('sm');

  @include sm {
    @include body-text('md');
  }

  span {
    font-weight: $semiBoldFontWeight;
  }
}

.preferenceText {
  text-align: center;

  span {
    @include body-text('lg', 700);
  }
}

.buttonsContainer {
  @include verticalGap(20px);
}

.disclaimersContainer {
  @include disclaimerContainer;
}
