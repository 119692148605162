@import 'src/styles/variables';
@import 'src/styles/mixins';

.reviewContainer {
  @include cardContainer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.reviewTitle {
  font-weight: $boldFontWeight;
  margin-bottom: 16px;
}

.content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
}

.reviewText {
  @include body-text(md);
  line-height: 160%;
}
