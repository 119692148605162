@import 'src/styles/mixins';

.note {
  font-size: 12px;
  font-weight: 300;
}

.yourIncomeDisclaimer {
  @include notification;
  text-align: center;
  color: $black100;
}
