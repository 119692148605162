@import 'src/styles/mixins';
@import 'src/styles/variables';

.term {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.termDiscount {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  border-radius: 12px;
  background-color: #e8e8fd;
  box-shadow: 0 0 0 4px #e8e8fd;
  margin-bottom: 8px;
}

.termTitle {
  @include body-text(lg);
  @include verticalGap(8px);

  flex-direction: row;
  align-items: center;
  color: $black60;

  &.clickable {
    cursor: pointer;
  }
}

.termValue {
  @include body-text(lg, 700);

  color: $black100;
}

.paymentOptions {
  z-index: 100;
  position: absolute;
  top: 24px;

  padding: 24px;

  border-radius: 24px;

  background-color: $white;
  /* For Screen */
  box-shadow: $otherBoxShadow;

  color: $black60;
  text-align: left;

  p {
    margin-bottom: 24px;
    line-height: 172%;
  }

  .firstButton {
    margin-bottom: 20px;
  }
}

.fullSize {
  margin-top: 16px;
  width: 100%;
}
