@import 'src/styles/variables';
@import 'src/styles/mixins';

.backLink {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 16px;

  @include backButton;
}
