@import 'src/styles/variables';
@import 'src/styles/mixins';

.formInput:not(:last-child) {
  @include formInputMargin;
}

.secondEmployerButton > * {
  display: flex;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $black20;
}

.cross {
  margin-right: 8px;
  font-size: 24px;
  transform: rotate(45deg);
}

.additionalIncomeText {
  font-size: 12px;
}

.loader {
  margin: 20px auto;
}
