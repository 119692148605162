@import 'src/styles/variables';
@import 'src/styles/mixins';

.inputsContainer {
  @include verticalGap(32px);
}

.buttonsContainer {
  @include verticalGap(20px);
}
