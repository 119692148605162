@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  @include cardContainer;
}

.title {
  font-weight: $boldFontWeight;
  margin-bottom: 16px;
}

.actionItems {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.description {
  @include body-text(md);
  line-height: 160%;
}
