@import 'src/styles/mixins';

.textContainer {
  @include verticalGap(18px);
  @include body-text(lg);
  text-align: center;
}

.contactContainer {
  @include verticalGap(24px);
  text-align: center;
  padding-top: 16px;

  .contactTitle {
    @include body-text(xl, 700);
  }

  .contactSubtitle {
    @include body-text(md);
  }

  @include sm {
    padding-top: 32px;
  }
}

.contact {
  margin-bottom: 8px;

  @include sm {
    width: 720px;
    max-width: 864px;
    align-self: center;
    box-sizing: border-box;
  }
}
