@import 'src/styles/variables';
@import 'src/styles/mixins';

.containerAlert {
  @include body-text(md, 600);

  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $red;
  text-align: center;

  svg {
    min-width: 24px;
  }
}

.contactUs {
  text-decoration: underline;
  cursor: pointer;
}
