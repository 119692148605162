@import 'src/styles/mixins';

.title {
  text-align: center;
  margin-bottom: 24px;
  font-size: 24px;
}

.subtitle {
  font-size: 16px;
  text-align: center;
  color: $black60;
}

.outputValueContainer {
  @include sm {
    margin-bottom: 40px;
  }

  margin-bottom: 24px;
}

.descriptionItems {
  display: flex;
  flex-direction: column;
  background-color: $white;
  text-transform: none;
  color: $black100;
  font-style: normal;
  font-size: 14px;

  .item {
    display: grid;
    align-items: center;
    grid-template-columns: 40px 1fr;
    margin-bottom: 12px;
  }

  .item:last-child {
    margin-bottom: 0;
  }

  .itemText {
    @include body-text(md);
    margin-left: 12px;
  }
}

.buttonContainer {
  @include verticalGap(20px);
}

.verified {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkIcon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.verifiedLabel {
  color: $green;
  text-transform: uppercase;
  font-weight: $boldFontWeight;
}
