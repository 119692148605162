@import 'src/styles/variables';
@import 'src/styles/mixins';

.buttonsContainer {
  @include verticalGap(20px);
}

.disclaimerContainer {
  @include disclaimerContainer;
}
