@import 'src/styles/mixins';

.lateFeeText,
.text {
  @include body-text(lg);
  text-align: center;
}

.text {
  color: $black80;
}

.title {
  @include body-text(xl, 700);
  text-align: center;
}

.contactContainer {
  @include verticalGap(16px);
}
