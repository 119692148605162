@import 'src/styles/variables';
@import 'src/styles/mixins';

.benefitsContainer {
  @include verticalGap(24px);
  @include body-text('lg');
  width: 100%;

  .benefitsCard {
    @include verticalGap(12px);

    flex-direction: row;
    align-items: center;
  }

  .benefitsCardIcon {
    width: 40px;
    height: 40px;
  }

  span {
    font-weight: $boldFontWeight;
  }
}
