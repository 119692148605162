@import 'src/styles/mixins';
@import 'src/styles/variables';

.title {
  @include body-text(lg, 700);
}

.totalLoanAmountContainer {
  display: flex;
  justify-content: space-between;
}

.loanBreakdownSeparatorLine {
  height: 1px;
  width: 100%;
  background-color: $black6;
}

.termTitle {
  @include body-text(lg);
  color: $black60;
}

.termComparison {
  display: flex;
  gap: 6px;
}

.termValue {
  @include body-text(lg, 700);

  color: $black100;
}

.textStrikeThrough {
  @include body-text(lg);

  text-decoration: line-through;
  text-decoration-thickness: 1px;
}

.separatorContainer {
  height: 1px;
  background-color: $black6;
  margin: 8px 0;
}

.expanderLabel {
  display: flex;
  align-items: center;
}

.loanBreakdownLine {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 8px;
}

.expanderContainer {
  @include body-text(sm);
}

.expanderOpen {
  border-bottom: 1px solid $black6;
  padding: 8px 0 12px;
}

.tradelineContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $black100;

  .termValue {
    padding-right: 24px;
  }
}
