@import 'src/styles/variables';
@import 'src/styles/mixins';

.loaderContainer {
  width: 100%;
  height: 400px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
