$poppinsFontStyle: 'Poppins', sans-serif;

$thinFontWeight: 100;
$extraLightFontWeight: 200;
$lightFontWeight: 300;
$regularFontWeight: 400;
$mediumFontWeight: 500;
$semiBoldFontWeight: 600;
$boldFontWeight: 700;
$extraBoldFontWeight: 800;
$blackFontWeight: 900;

$contentIndex: 2;
$inputDropdownIndex: 9;
$pageLoaderIndex: 10;

$purple50: #f1ecff;
$purple100: #e7defe;
$purple200: #cebdfe;
$purple300: #b39cfc;
$purple400: #9c82fa;
$purple500: #795af7;
$purple600: #5b41d4;
$purple700: #422db1;
$purple800: #2c1c8f;
$purple900: #1c1176;

$white: #ffffff;
$black3: #f9fafc;
$black6: #f2f4f7;
$black10: #e2e5ec;
$black20: #c5cbd4;
$black40: #a1aab4;
$black60: #6d7784;
$black80: #454b54;
$black100: #1a1e23;

$blue: #0d95f7;
$deepBlue: #2147c9;
$blue300: #7d96e8;
$blue400: #077acc;
$blue500: #2b46c2;
$blueLight: #e7f0fe;
$darkBlue: #0c88e1;
$turquoise: #42cfee;
$turquoiseLight: #e4fafd;
$red: #ff5a64;
$redLight: #ffe2df;
$redWarning: #ff3b30;
$green: #42dca4;
$greenLight: #eaf8e5;
$darkGreen: #19865f;
$grayLight: #e7f0fe;
$irisLight: #e8e8fd;
$yellow: #ffde03;
$yellowLight: #fff8cd;
$gold: #e0ca91;
$silver: #c4c4c4;

$red500: #e53e3e;
$red600: #c53030;

$boxShadow: 0 3px 10px rgba(48, 52, 101, 0.07);
$deepBoxShadow: 0 16px 20px 0 rgba(36, 85, 158, 0.08);
$otherBoxShadow: 0 16px 24px 0 rgba(0, 0, 0, 0.25);

$primaryColor: $purple500;
$primaryColorLight: $purple300;
