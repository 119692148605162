@import 'src/styles/variables';
@import 'src/styles/mixins';

.text {
  @include body-text(lg);
  text-align: center;
}

.inputContainer {
  @include verticalGap(16px);
}
