@import 'src/styles/variables';
@import 'src/styles/mixins';

.containerSavings {
  @include verticalGap(16px);

  align-items: center;
  padding: 8px 0;

  p {
    @include body-text('lg');

    span {
      font-weight: $boldFontWeight;
    }
  }

  .spacingLine {
    width: 100%;
    height: 1px;
    background-color: $black10;
  }

  .benefitsListContainer {
    gap: 16px;
  }
}
