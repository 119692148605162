@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  @include stepsContainer;
  @include verticalGap(30px);
  text-align: center;
}

.title {
  @include h2;
}

.description {
  @include body-text(lg, 700);
}

.keepInTouchText {
  @include body-text(lg);
}
