@import '../../../styles/mixins';
@import '../../../styles/variables';

.subtitle {
  @include body-text(lg, 700);
  text-align: center;
}

.safeSenderMessage {
  @include body-text(lg);
  text-align: center;
}
