@import 'src/styles/variables';
@import 'src/styles/mixins';

.monthlyBudgetContainer {
  @include verticalGap(16px);
  padding: 16px;
  background-color: $greenLight;
  border-radius: 8px 8px 0 0;

  span {
    text-align: center;
    font-size: 16px;
    line-height: 28px;
    font-weight: $mediumFontWeight;
  }
}

.whenNeededContainer {
  background-color: $yellowLight;
  border-radius: 0 0 8px 8px;
}

.spendCategories {
  @include verticalGap(8px);
}

.spendCategory {
  @include verticalGap(8px);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: $white;

  span {
    font-weight: $semiBoldFontWeight;
  }
}

.limitsContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  span {
    font-weight: $boldFontWeight;
  }
}

.preferenceText {
  text-align: center;

  span {
    @include body-text('lg', 700);
  }
}

.buttonsContainer {
  @include verticalGap(20px);
}

.disclaimerContainer {
  @include disclaimerContainer;
}
