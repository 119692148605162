@import 'src/styles/variables';
@import 'src/styles/mixins';

.loaderContainer {
  display: flex;
  justify-content: center;
}

.textContainer {
  @include verticalGap(18px);
  text-align: center;

  p {
    @include body-text(lg);
  }
}

.disclaimerContainer {
  @include disclaimerContainer;
  text-align: left;

  @include sm {
    text-align: center;
  }
}
