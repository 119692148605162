@import 'src/styles/mixins';

.subtitleContainer {
  @include verticalGap(18px);
}

.inputs {
  width: 100%;
}

.formInput:not(:last-child) {
  @include formInputMargin;
}
