@import 'src/styles/mixins';
@import 'src/styles/variables';

.expanderLabel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.breakdownMainItem {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.breakdownContentSection {
  @include verticalGap(12px);

  padding-top: 12px;
}

.breakdownItem {
  display: flex;
  justify-content: space-between;
  padding-left: 32px;

  @include sm {
    padding-right: 31px;
  }
}

.breakdownTitle {
  @include body-text(lg);

  color: $black60;
}

.APRComparisonContainer {
  @include verticalGap(6px);

  flex-direction: row;
  align-items: center;
  padding-right: 8px;
}

.breakdownValue {
  @include body-text(lg);

  color: $black100;
}

.textStrikeThrough {
  text-decoration: line-through;
  text-decoration-thickness: 1px;
}

.bold {
  font-weight: $boldFontWeight;
}

.discountDescription {
  @include notification;

  padding: 8px 0 0 32px;
  text-align: left;
  color: $black60;
}
