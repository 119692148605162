@import 'src/styles/variables';
@import 'src/styles/mixins';

.mainContainer {
  @include verticalGap(24px);

  .title {
    @include h1;
    text-align: center;
  }

  .testimonialContainer {
    @include verticalGap(32px);

    .subtitle {
      @include body-text('md');
      text-align: center;
      padding: 0 10px;

      @include sm {
        @include body-text('lg');
      }
    }

    .card {
      @include verticalGap(24px);
      position: relative;
      overflow: hidden;
      border: 1px solid $black10;
      border-radius: 12px;
      box-shadow: $deepBoxShadow;
      padding: 8px 24px 24px;
      align-items: center;
      justify-content: center;

      .overlayImageContainer {
        position: relative;
        border-radius: 50% 50% 50% 0;
        border: 1px dashed $purple50;
        padding: 7px;

        .outerImageContainer {
          border-radius: 50% 50% 50% 0;
          border: 1px dashed $purple100;
          padding: 7px;

          .imageContainer {
            overflow: hidden;
            border-radius: 50% 50% 50% 0;
            border: 7px solid $purple200;
            width: 124px;
            height: 124px;

            .image {
              width: 100%;
              height: 100%;
            }
          }

          &::after {
            content: '';
            position: absolute;
            top: -9px;
            left: -9px;
            width: 100%;
            height: 100%;
            border-radius: 50% 50% 50% 0;
            border: 1px dashed $purple50;
            padding: 8px;
            opacity: 0.8;
          }

          &::before {
            content: '';
            position: absolute;
            top: -17px;
            left: -17px;
            width: 100%;
            height: 100%;
            border-radius: 50% 50% 50% 0;
            border: 1px dashed $purple50;
            padding: 16px;
            opacity: 0.4;
          }
        }
      }

      .testimonialContainer {
        position: relative;
        @include verticalGap(16px);

        .testimonial {
          @include body-text(lg);
          color: $black60;
        }

        .personalInfoContainer {
          position: relative;
          text-align: center;

          .name {
            @include body-text(lg, $semiBoldFontWeight);
          }

          .credentials {
            @include body-text(md, $regularFontWeight);
            color: $black60;
          }

          .closeQuotesIcon {
            position: absolute;
            top: -20px;
            right: 0;
          }
        }

        .openQuotesIcon {
          position: absolute;
          top: -25px;
          left: 0;
        }
      }
    }

    .buttonsContainer {
      @include verticalGap(20px);
    }

    .disclosure {
      @include body-text(sm);
      color: $black60;
    }
  }
}
