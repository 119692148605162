@import 'src/styles/mixins';
@import 'src/styles/variables';

.nextStepContainer {
  @include verticalGap(24px);
  @include body-text(lg);
  text-align: center;

  p {
    font-weight: $boldFontWeight;
  }

  div {
    @include verticalGap(16px);
  }
}
