@import 'src/styles/variables';
@import 'src/styles/mixins';

.cardPreviewContainer {
  position: relative;
  align-self: center;
  pointer-events: none;
  user-select: none;

  .cardNameContainer {
    display: flex;
    flex-direction: row-reverse;
    font-family: Poppins;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: left;

    .primaryText {
      position: absolute;
      bottom: 22%;
      left: 7%;
    }

    .secondaryText {
      position: absolute;
      bottom: 12%;
      left: 7%;
    }
  }

  .gold {
    color: $gold;
  }

  .silver {
    color: $white;
  }

  .purple {
    color: $white;
  }
}

.cardPreviewSmall {
  @include body-text(xs);

  div svg {
    width: 230px;
    height: 145px;
  }
}

.cardPreviewLarge {
  @include body-text(sm);

  div svg {
    width: 293px;
  }
}
