@import 'src/styles/variables';
@import 'src/styles/mixins';

.containerBalanceDiagram {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerBalanceDiagram::after {
  content: url('/images/simple-arrow-right-rounded-icon.svg');
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 36px;
  height: 36px;
}

.cardBalance {
  @include verticalGap(4px);

  align-items: center;
  width: 100%;
  max-width: 162px;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid $black10;
  background: $white;
  box-shadow: $deepBoxShadow;

  svg {
    width: 64px;
    height: 64px;
  }

  &:first-child {
    margin-right: 12px;
  }
}

.cardBalanceTitle {
  @include body-text('md', 500);
  color: $black80;
}

.benefitsListContainer {
  @include body-text('md');

  @include sm {
    @include body-text('lg');
  }
}

.importDebtDisclaimer {
  @include notification;
  text-align: center;
}

.buttonsContainer {
  @include verticalGap(20px);
}

.preferenceText {
  text-align: center;

  span {
    @include body-text('lg', 700);
  }
}

.disclaimersContainer {
  @include disclaimerContainer;
}
